import { CoverParticles } from './components/cover-particles.tsx';
import { Header } from './components/header.tsx';
import {Introduction} from './components/introduction.tsx';
import { Navbar } from './components/navbar.tsx';
import {TransitionPage} from './components/transition-page.tsx';

export default function Home() {
  return (<>
    <Header/>
    <Navbar/>
    </>
  );
}
