// index.js
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Layout from "./Layout.js";

ReactDOM.render(
  <React.StrictMode>
    {/* <Layout> */}
      <App />
    {/* </Layout> */}
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
