import { CoverParticles } from "../components/cover-particles.tsx";
import { Introduction } from "../components/introduction.tsx";
import { TransitionPage } from "../components/transition-page.tsx";
import React from "react";

export default function Inicio() {
  return (
    <>
      <meta
        name="description"
        content="Especialistas en diseño web y marketing digital en Barcelona. Más de 10 años de experiencia creando Páginas Web y Plataformas SaaS personalizadas para llevar tu empresa al siguiente nivel. ¡Contáctanos ahora!"
      />
      <TransitionPage />
      <div className="flex min-h-[100vh] h-full bg-no-repeat mt-20 ">
        <CoverParticles />
        <Introduction />
      </div>
    </>
  );
}
