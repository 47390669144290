import React from 'react';
import { socialNetworks } from "../data.tsx";
import { Link, BrowserRouter } from 'react-router-dom'; // Importa BrowserRouter de react-router-dom
import  {MotionTransition}  from './transition-component.tsx';

export const Header = () => {
    return (
        <BrowserRouter> {/* Envuelve el componente Header con BrowserRouter */}
            <MotionTransition position="bottom" className="absolute z-40 inline-block w-full top-5 md:top-10">
                <header>
                    <div className="container justify-between max-w-6xl mx-auto md:flex">
                        <Link to='./'> {/* Usa Link de react-router-dom con el atributo "to" en lugar de href */}
                            <h1 className="my-3 text-4xl font-bold text-center md:text-left">
                                Momdel
                                <span className="text-pink-800"> Dev</span>
                            </h1>
                        </Link>
                        <div className="flex items-center justify-center gap-7">
                            {socialNetworks.map(({ logo, src, id }) => (
                                <a
                                    key={id} 
                                    href={src}
                                    target="_blank"
                                    rel="noopener noreferrer" 
                                    className="transition-all duration-300 hover:text-pink-800"
                                >
                                    {logo}
                                </a>
                            ))}
                        </div>
                    </div>
                </header>
            </MotionTransition>
        </BrowserRouter>
    );
}
