import React from 'react';
import { motion } from 'framer-motion';
import { fadeIn } from '../utils/motion-transitions.tsx';

export type MotionTransitionProps = {
    children: React.ReactNode;
    className?: string;
    position: 'right' | 'bottom';
};

export const MotionTransition: React.FC<MotionTransitionProps> = ({ children, className, position }) => {
    return (
        <motion.div
            variants={fadeIn(position)}
            initial="hidden"
            animate="visible"
            exit="hidden"
            className={className}
        >
            {children}
        </motion.div>
    );
};

export default MotionTransition;
