import React from 'react';
import { TypeAnimation } from 'react-type-animation';

export const Introduction = () => {
    return (
        <div className="z-20 w-full h-full bg-darkBg/60 flex justify-center items-center overflow-hidden mb-32">
            <div className="max-w-md p-6 text-center">
                {/* Quitamos la propiedad priority del elemento img */}
                <img src="/lgrmagentaTras.png" width="400" height="400" alt="Avatar" className="ml-12" />
                <h2 className="mb-5 text-2xl leading-tight">Especialistas en <br />
                    <TypeAnimation
                        sequence={[
                            'Páginas Web',
                            1000,
                            'Plataformas SaaS',
                            1000,
                            'Marketing Digital',
                            1000,
                            'Fotografía y Vídeo',
                            1000
                        ]}
                        wrapper="span"
                        speed={50}
                        repeat={Infinity}
                        className="font-bold text-pink-800"
                    />
                </h2>

                <h1 className="mx-auto mb-2 text-xl md:text-xl md:mx-0 md:mb-8">
                    Somos una empresa de diseño web de Barcelona, con más de 10 años en el sector ayudando a empresas a tener Páginas Web y Plataformas SaaS adaptadas a sus necesidades específicas. Para llevar a tu empresa al siguiente nivel.
                </h1>

                <div className="flex flex-col md:flex-row items-center justify-center gap-3 md:justify-start md:gap-10 xl:ml-12">
                    <a href="/portafolio" className="px-3 py-2 transition-all border-2 cursor-pointer text-md w-fit rounded-xl hover:shadow-md hover:shadow-white/50">
                        Ver proyectos
                    </a>
                    <a href="/contacto"
                        className="px-3 py-2 transition-all border-2 cursor-pointer text-md w-fit text-pink-800 border-pink-800 rounded-xl hover:shadow-md hover:shadow-pink-800">
                        Contáctanos
                    </a>
                </div>
            </div>
        </div>
    );
}
