import styles from "./page.module.css";
import Lenis from "@studio-freight/lenis";
import { useEffect } from "react";
import { useSpring } from "framer-motion";
import { Description } from "../components/descripcion/description.jsx";
import React from "react";
import Galeria from "../components/galeria/index.jsx";
import { TransitionPage } from "../components/transition-page.tsx";

const projects = [
  {
    name: "Retrato",
    handle: "retrato",
  },
  {
    name: "Fotografía de estudio",
    handle: "estudio",
  },
  {
    name: "vídeo",
    handle: "video",
  },
  {
    name: "Community Manager",
    handle: "manager",
  },
];

export default function Imagenes() {
  const spring = {
    stiffness: 150,
    damping: 15,
    mass: 0.1,
  };

  const mousePosition = {
    x: useSpring(0, spring),
    y: useSpring(0, spring),
  };

  useEffect(() => {
    const lenis = new Lenis();

    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);
  }, []);

  const mouseMove = (e) => {
    const { clientX, clientY } = e;
    const targetX = clientX - (window.innerWidth / 2) * 0.25;
    const targetY = clientY - (window.innerWidth / 2) * 0.3;
    mousePosition.x.set(targetX);
    mousePosition.y.set(targetY);
  };

  return (<>
    <TransitionPage />

    <div onMouseMove={mouseMove} className={styles.main}>

        <meta
          name="description"
          content="Explora nuestra cautivadora galería de imágenes animadas, exhibiendo nuestros servicios audiovisuales. Descubre retratos, fotografía de estudio, vídeos y gestión comunitaria. ¡Contáctanos para dar vida a tu visión!"
        />
      {projects.map(({ handle }, i) => {
        return (
          <Galeria mousePosition={mousePosition} handle={handle} key={i} />
        );
      })}
      <Description mousePosition={mousePosition} projects={projects} />
    </div>
    </>
  );
}
