import React from "react";

import { dataPortfolio } from "../data.tsx";

import { AvatarPortfolio } from "../components/avatar-portfolio.tsx";
import { CircleImage } from "../components/circle-image.tsx";
import { TransitionPage } from "../components/transition-page.tsx";
import { ContainerPage } from "../components/container-page.tsx";
import { PortfolioBox } from "../components/portfolio-box.tsx";
import { SliderServices } from "../components/slider-services.tsx";

const PortfolioPage = () => {
  return (
    <ContainerPage>
      <meta
        name="description"
        content="Explora nuestro portafolio de páginas web y plataformas desarrolladas a medida para nuestros clientes. Descubre nuestros últimos trabajos realizados y encuentra inspiración para tu próximo proyecto. ¡Contáctanos para llevar tu visión al siguiente nivel!"
      />

      <TransitionPage />
      <CircleImage />
      {/* <AvatarPortfolio /> */}
      <div className="flex flex-col justify-center h-full xl:-mt-32">
        <div className="grid items-center justify-center min-h-screen max-w-5xl gap-6 px-4 mx-auto md:px-6 lg:px-0 md:grid-cols-2 ">
          <div className="max-w-lg md:max-w-none">
            <h1 className="text-2xl leading-tight text-center md:text-4xl md:mb-5 py-2">
              Nuestros{" "}
              <span className="font-bold text-pink-800 "> servicios</span>
            </h1>
            <p className="mb-3 text-sm md:text-base text-gray-600">
              En Momdel nos dedicamos a brindar soluciones de desarrollo web
              tanto para páginas web más atractivas hasta plataformas adaptadas
              a vuestras necesidades. Expertos en tecnologías muy específicas,
              como HTML, CSS, PHP y JavaScript, para diseñar interfaces de
              usuario intuitivas y adaptables. También ofrecemos servicios
              audiovisuales como el de fotografía, vídeo y especialistas en
              creación de contenido para redes sociales
            </p>
            <a
              href="/contacto"
              className="block w-full px-4 py-2 text-center rounded-lg bg-pink-800 text-white hover:bg-pink-700"
            >
              Contáctanos
            </a>
          </div>

          <div className="relative z-10 grid max-w-5xl gap-6 mx-auto mt-4 md:grid-cols-4 ">
            <SliderServices />
          </div>
        </div>
      </div>
    </ContainerPage>
  );
};

export default PortfolioPage;
