import React from "react";

import { dataPortfolio } from "../data.tsx";

import { AvatarPortfolio } from "../components/avatar-portfolio.tsx";
import { CircleImage } from "../components/circle-image.tsx";
import { TransitionPage } from "../components/transition-page.tsx";
import { ContainerPage } from "../components/container-page.tsx";
import { PortfolioBox } from "../components/portfolio-box.tsx";

const PortfolioPage = () => {
  return (
    <ContainerPage>
      <meta
        name="description"
        content="Explora nuestro portafolio de páginas web y plataformas desarrolladas a medida para nuestros clientes. Descubre nuestros últimos trabajos realizados y encuentra inspiración para tu próximo proyecto. ¡Contáctanos para llevar tu visión al siguiente nivel!"
      />

      <TransitionPage />
      {/* <CircleImage /> */}
      {/* <AvatarPortfolio /> */}
      <div className="flex flex-col justify-center h-full">
        <h1 className="text-2xl leading-tight text-center md:text-4xl md:mb-5">
          Nuestros últimos{" "}
          <span className="font-bold text-pink-800 ">trabajos realizados</span>
        </h1>

        <div className="relative z-10 grid max-w-5xl gap-6 mx-auto mt-4 md:grid-cols-4 mb-32">
          {dataPortfolio.map((data) => (
            <PortfolioBox key={data.id} data={data} />
          ))}
        </div>
      </div>
    </ContainerPage>
  );
};

export default PortfolioPage;
