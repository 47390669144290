// Galeria.js
import styles from "./style.module.css"; // Cambiamos el nombre del archivo de estilo
import { motion } from "framer-motion";

export default function Galeria({ mousePosition, handle }) {
  const { x, y } = mousePosition;

  return (
    <div className={styles.gallery}>
      <div className={styles.imageContainer}>
        <img src={`/images/${handle}/background.jpg`} alt="image" />
      </div>
      <motion.div className={styles.vignette} style={{ x, y }}>
        <img src={`/images/${handle}/1.jpg`} alt="image" />
        {handle === "retrato" && (
          <div className="flex items-center px-3 py-2 transition duration-150 rounded-full cursor-pointer hover:bg-pink-800 border-2 border-pink-800 mt-5">
            <a href="https://anerco.es" target="_blank" rel="noopener noreferrer">
              Saber más sobre nuestro trabajo
            </a>
          </div>
        )}
      </motion.div>
    </div>
  );
}
