import axios from "axios";
import { useState } from "react";
import React from "react";
import { TransitionPage } from "../components/transition-page.tsx";

function Contacto() {
  const [nombre, setNombre] = useState("");
  const [email, setEmail] = useState("");
  const [mensaje, setMensaje] = useState(""); // Cambiado a "mensaje" en lugar de "apellido"
  const [camposIncompletos, setCamposIncompletos] = useState(false);
  const [emailInvalido, setEmailInvalido] = useState(false);

  const validarEmail = (email) => {
    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regexEmail.test(email);
  };

  const enviarFormulario = async () => {
    if (nombre.trim() === "" || email.trim() === "" || mensaje.trim() === "") { // Cambiado "apellido" por "mensaje"
      setCamposIncompletos(true);
      setEmailInvalido(false);
    } else if (!validarEmail(email)) {
      setEmailInvalido(true);
      setCamposIncompletos(false);
    } else {
      try {
        await axios.post("https://formspree.io/f/mrgneedn", {
          nombre,
          email,
          mensaje, // Cambiado "apellido" por "mensaje"
        });
        setNombre("");
        setEmail("");
        setMensaje(""); // Cambiado "apellido" por "mensaje"
        setCamposIncompletos(false);
        setEmailInvalido(false);
        console.log("Formulario enviado correctamente");
      } catch (error) {
        console.error("Error al enviar el formulario:", error);
      }
    }
  };

  return (
    <>
      <TransitionPage />

      <div className="flex items-center justify-center mt-36 ">
        <div className="flex flex-col   w-full sm:w-5/6 md:w-4/6 lg:w-3/6 xl:w-3/6 rounded-xl shadow-xl text-black m-4">
          <div className="p-8 space-y-4">
            <h2 className="text-3xl font-semibold text-white">
              ¿NECESITAS INFORMACIÓN O AYUDA?
            </h2>
            <p className="text-lg text-white">
              Completa el formulario y nos pondremos en contacto contigo.
            </p>

            {/* Campos de entrada */}
            <div className="flex flex-col space-y-2">
              <input
                type="text"
                id="nombre"
                placeholder="Tu nombre"
                value={nombre}
                onChange={(e) => setNombre(e.target.value)}
                className={`bg-white h-14 border-2 border-green-900 rounded-xl px-4 ${
                  camposIncompletos && nombre.trim() === ""
                    ? "border-red-500"
                    : ""
                }`}
              />

              <input
                type="email"
                id="email"
                placeholder="Tu correo electrónico"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={`bg-white h-14 border-2 border-green-900 rounded-xl px-4 ${
                  (camposIncompletos && email.trim() === "") || emailInvalido
                    ? "border-red-500"
                    : ""
                }`}
              />

              {/* Cambio a un área de texto para el mensaje */}
              <textarea
                id="mensaje"
                placeholder="Tu mensaje o duda"
                value={mensaje}
                onChange={(e) => setMensaje(e.target.value)}
                rows={5} // Ajusta el tamaño del área de texto
                className={`bg-white border-2 border-green-900 rounded-xl px-4 py-3 ${
                  camposIncompletos && mensaje.trim() === ""
                    ? "border-red-500"
                    : ""
                }`}
              />

              {/* Mensaje de error para email inválido */}
              {emailInvalido && (
                <p className="text-red-500">
                  Ingrese un correo electrónico válido.
                </p>
              )}

              {/* Mensaje de error para campos incompletos */}
              {camposIncompletos && (
                <p className="text-red-500">
                  Falta rellenar campos obligatorios.
                </p>
              )}

              {/* Botón de enviar */}
              <button
                onClick={enviarFormulario}
                className="bg-pink-800 text-xl p-3 rounded-md hover:bg-pink-900 text-white mt-4"
              >
                Enviar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="h-32"></div>

    </>
  );
}

export default Contacto;
