import React, { useState } from "react";
import { BrowserRouter, NavLink, Route, Routes, useLocation } from "react-router-dom";
import { itemsNavbar } from "../data.tsx";
import MotionTransition from "./transition-component.tsx";
import PortfolioPage from "../pages/portafolio.tsx";
import Home from "../App.js";
import ServicesPage from "../pages/servicios.tsx";
import AboutMePage from "../pages/sobreMi.tsx";
import TestimonialsPage from "../pages/testimonios.tsx";
import {
  BookText,
  CodeSquare,
  HomeIcon,
  UserRound,
  Speech,
} from "lucide-react";
import Inicio from "../pages/inicio.tsx";
import Imagenes from "../pages/imagenes.tsx";
import Contacto from "../pages/Contacto.tsx";



export function Navbar() {
  return (
    <BrowserRouter>
      <MotionTransition
        position="right"
        className="fixed z-40 flex flex-col items-center justify-center w-full mt-auto h-max bottom-10"
      >
        <nav>
          <div className="flex items-center justify-center gap-2 px-4 py-1 rounded-full bg-white/15 background-blur-sm">
            <div className="flex items-center px-3 py-2 transition duration-150 rounded-full cursor-pointer hover:bg-pink-800">
              <NavLink to="/" className="flex items-center">
                <HomeIcon size={25} color="black" strokeWidth={1} />
                <span className="ml-2 hidden md:inline">Inicio</span>
              </NavLink>
            </div>
            <div className="flex items-center px-3 py-2 transition duration-150 rounded-full cursor-pointer hover:bg-pink-800">
              <NavLink to="/servicios" className="flex items-center">
                <BookText size={25} color="black" strokeWidth={1} />
                <span className="ml-2 hidden md:inline">Servicios</span>
              </NavLink>
            </div>
            <div className="flex items-center px-3 py-2 transition duration-150 rounded-full cursor-pointer hover:bg-pink-800">
              <NavLink to="/portafolio" className="flex items-center">
                <CodeSquare size={25} color="black" strokeWidth={1} />
                <span className="ml-2 hidden md:inline">Desarrollo Web</span>
              </NavLink>
            </div>
            <div className="flex items-center px-3 py-2 transition duration-150 rounded-full cursor-pointer hover:bg-pink-800">
              <NavLink to="/imagenes" className="flex items-center">
                <UserRound size={25} color="black" strokeWidth={1} />
                <span className="ml-2 hidden md:inline">Fotografía y Vídeo</span>
              </NavLink>
            </div>
            <div className="flex items-center px-3 py-2 transition duration-150 rounded-full cursor-pointer hover:bg-pink-800">
              <NavLink to="/contacto" className="flex items-center">
                <Speech size={25} color="black" strokeWidth={1} />
                <span className="ml-2 hidden md:inline">Contacto</span>
              </NavLink>
            </div>
          </div>
        </nav>
      </MotionTransition>
      <section>
        <Routes>
          <Route path="/" element={<Inicio />} />
          <Route path="/portafolio" element={<PortfolioPage />} />
          <Route path="/servicios" element={<ServicesPage />} />
          <Route path="/imagenes" element={<Imagenes />} />
          <Route path="/testimonios" element={<TestimonialsPage />} />
          <Route path="/contacto" element={<Contacto />} />
          <Route path="*" element={<Inicio />} />
        </Routes>
      </section>
    </BrowserRouter>
  );
}
