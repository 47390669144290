import React from 'react';

export const CircleImage = () => {
    return (
        <div className="bottom-0 right-0 hidden md:inline-block md:absolute">
            <img src="/circles.png" width="300" height="300" className="w-full h-full" alt="Particles" />
        </div>
    );
}

